import React, { Component } from 'react'
import { graphql } from "gatsby"
import { Location } from '@reach/router'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Button from 'react-bootstrap/Button'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import ScheduleConsultation from '../components/page-sections/schedule-consultation'


const BlogPostTemplate = class extends Component {
  // constructor(props) {
  //   super(props)
  //   // this.html = this.props.data.markdownRemark.code.body
  //   // this.resource = this.props.data.markdownRemark.frontmatter
  // }
  render() {
    const { markdownRemark } = this.props.data
    return (
      <Layout location={this.props.location}>
        <SEO title="G Jones for Hormones" />
        <Container>
          <Row style={{ marginBottom: '3rem', marginTop: '3rem' }}>
            <Col>
              <h1>{markdownRemark.frontmatter.title}</h1>
              <p className="date">
                Posted by {markdownRemark.frontmatter.author} on {markdownRemark.frontmatter.date}
              </p>
              <hr style={{ marginBottom: '2rem' }} />
              <div dangerouslySetInnerHTML={{
                __html: markdownRemark.html
              }} />
            </Col>
          </Row>
        </Container>
        <ScheduleConsultation />
      </Layout>
    )
  }
}

export default props => (
  <Location>
    {locationProps => <BlogPostTemplate {...locationProps} {...props} />}
  </Location>
)

export const query = graphql`
  query BlogPostQuery($nodeId: String!) {
    markdownRemark(id: {eq: $nodeId}) {
      html
      frontmatter {
        title
        author
        date(formatString: "MMM DD, YYYY")
      }
    }
  }
`
